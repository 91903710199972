<template>
  <AuthLayout>
    <div class="auth">
      <h1 class="auth__title">{{ title }}</h1>
      <div class="auth__body">
        <RegisterForm>
          <BaseButton class="auth__back" transparent :to="{ name: 'auth-login' }" replace><IconArrowLeft />Назад</BaseButton>
        </RegisterForm>
      </div>
    </div>
  </AuthLayout>
</template>

<script setup>
import { IconArrowLeft } from '@tabler/icons-vue'
const router = useRouter()
const route = useRoute()

const role = ref(route.params.role)

const title = computed(() => ({ menti: 'Найти ментора', client: 'Разместить проект' })[role.value])
useHead({ title })
</script>

<style scoped lang="scss">

</style>
